
function DayOne({content}) {

    window.document.body.style.backgroundColor = "#16324f";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="dayone">
    
                <div className="m-10 p-4 text-3xl lg:text-4xl" id="title">Türchen 1 </div>
    <div className="poemblock w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
        Die richtige Person.<br/>
Auf der langen Suche<br/>
Nach dieser einen Person<br/>
Verpasst man sie in einem<br/>
Scheinbar<br/>
Bedeutungslosen Moment<br/>
Schaut man nicht genau hin.<br/>
Denn es ist Glück, wenn dir der Zufall<br/>
Willkürlich gewürfelt<br/>
In einem dieser bedeutungslosen Momente<br/>
Deine Person vorstellt.<br/>
- Reini
 
    </div>
   </div>
    </div>

  );
}

export default DayOne;