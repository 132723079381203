
function DayTwelve({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="daytwelve">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl" >Türchen 12</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    <div className="pb-5">
    Idee:<br/><br/>
    Setzt euch zusammen und bereitet 12 Blätter für jeden Monat im Jahr vor.<br/><br/>
    Für jedes Blatt setzt ihr euch einen Timer<br/> und schreibt alles auf, was euch zu diesem Monat einfällt.<br/><br/>
    Präsentiert am Ende eure Ergebnisse!

    </div>
      </div>
   </div>
    </div>

  );
}

export default DayTwelve;