
function DaySix({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="daysix">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl" >Türchen 6</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    <div className="pb-5" class="text">
    Bald sehen wir uns! Und das machen wir später zusammen &lt;3<br/><br/>
    </div>
      </div>
    <iframe width="336" height="189" src="https://www.youtube.com/embed/5L0WUcbwMtc?si=mTWu5YiV_5azNIMI&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
   </div>
    </div>

  );
}

export default DaySix;