
function Day14({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="daythirteen">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl" >Türchen 14</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    <div className="pb-5">
    Für eine schöne Aussicht in Budapest<br/><br/>
    <a href={"https://www.sommertage.com/die-schoensten-ausblicke-ueber-budapest-tipps/"}>
      <div className="flex justify-center">
        <img className="w-1/2 lg:w-1/3 h-1/2 object-cover" src={require('../assets/buda1.png')}/>
      </div>
    </a>
    </div>
      </div>
   </div>
    </div>

  );
}

export default Day14;