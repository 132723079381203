import logo from './logo.svg';
import './App.css';
import PageContainer from './components/PageContainer';

function App() {
  return (
    <div className="App">
      <PageContainer></PageContainer> 
    </div>
  );
}

export default App;
