
function DaySeven({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="dayseven">
      <div style={{color:"#FF4365"}} className="text">
        Zweiter Advent schon!
      </div>
                <div className="m-10 p-4 text-4xl lg:text-5xl" >Türchen 8</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    <div className="pb-5" class="text">
    Heute - Urlaubsplanung:<br/><br/>
    Setzt euch gemeinsam hin und plant euren Urlaub :&#41;	
    </div>
      </div>
    
   </div>
    </div>

  );
}

export default DaySeven;