
function DayEight({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="dayeight">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl title" >Türchen 7</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    <div className="pb-5" class="text">
    Filmeabend<br/><br/>
    Kauft eure Lieblingssnacks und macht einen Filmeabend zusammen!	
    </div>
      </div>
    
   </div>
    </div>

  );
}

export default DayEight;