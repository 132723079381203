
function DayTen({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="dayseven">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl title" >Türchen 10</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    <div className="pb-5" class="text">
    Ein Paar Songs für dich<br/><br/>
    </div>
      </div>
      <iframe style={{borderRadius: "12px", paddingBottom: "5px"}} src="https://open.spotify.com/embed/track/3bhnl8X5r4TmarRbsTqsQS?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      <iframe style={{borderRadius: "12px", paddingBottom: "5px"}} src="https://open.spotify.com/embed/track/2OSaZxxE7Pyfs2LECwm0fe?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      <iframe style={{borderRadius: "12px", paddingBottom: "5px"}} src="https://open.spotify.com/embed/track/17XfJHLq0DvhU9Dj2pUlvp?utm_source=generator&theme=0" width="100%" height="152" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
   </div>
    </div>

  );
}

export default DayTen;