
function DayTwo({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="daytwo">
    
                <div className="m-10 p-4 text-3xl lg:text-4xl" >Türchen 2 </div>
    <div className="poemblock w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    <div className="pb-5">
    Hier eine kleine Playlist für dich mein Herz!!
    </div>
    <iframe  src="https://open.spotify.com/embed/playlist/2YMPQefzcTDCC1XlYzGIVh?utm_source=generator&theme=0" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
      </div>
   </div>
    </div>

  );
}

export default DayTwo;