
function DayFive({content}) {

    window.document.body.style.backgroundColor = "#FFFCF9";
    window.document.body.style.color = "#16324f";

  return (
    <div className="day place-items-center content-center" id="dayfive">
    
                <div className="m-10 p-4 text-4xl lg:text-5xl" >Türchen 5</div>
    <div className="text-xl content-center w-11/12 md:w-1/2 place-items-center">
    <div className="place-items-center w-11/12 lg:w-1/2">
    <div className="pb-5">
    Kleine Inspiration, was wir zwei nach dem Studium machen &lt;3<br/><br/>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/RSJF-LpjweY?si=eWjKodOCzP2V5-tR&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

    </div>
      </div>
   </div>
    </div>

  );
}

export default DayFive;